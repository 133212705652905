import React, { useEffect, useRef, useState } from "react";
// import React, { useRef, useState } from "react";
// import React, { useRef, useState ,useEffect } from "react";
// import axios from "axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { Button } from "antd";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsPlusLg } from "react-icons/bs";
import Pagination1 from "react-js-pagination";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import "../App.css";
import API_PATH from "../Constants/api-path";
// import MailEditor from "../MailEditor";
import { Editor } from "@tinymce/tinymce-react";
import { Checkbox } from "antd";
import countryList from "./CountryList";

const config = {
  readonly: false,
  height: "280px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "|",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

export const CreateProjectModal2 = () => {
  let animatedComponents = makeAnimated();
  const loginDetails = JSON.parse(localStorage.getItem("userData"));
  const [startDate, setStartDate] = useState("");
  const [value, setValue] = useState("");
  const [inputList, setInputList] = useState([{ company: "" }]);
  const [selectList, setSelectList] = useState([{ country: "" }]);
  const [emailList, setEmailList] = useState([{ email: "" }]);
  const [ModalToggle, setModalToggle] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [allDatas, setAlldatas] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [call_recorded, setCall_recorded] = useState(0);
  const [loading1, setLoading1] = useState(false);

  const [isTitle, setIsTitle] = useState("");
  const [isNumber, setIsNumber] = useState("");
  const [isDate, setIsDate] = useState("");
  const [isOverview, setIsOverview] = useState("");
  const [inputValue, setInputValue] = useState("");

  const userData = JSON.parse(localStorage.getItem("userData"));

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.access_token}`,
  };

  const handleModalClose = () => {
    setInputValue("");
  };

  const inputRef = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();
  const inputRef7 = useRef();

  const handleClose = () => {
    setStartDate("");
    setValue("");
    inputRef.current.value = "";
    inputRef2.current.value = "";
    // inputRef3.current.value = "";
    // inputRef4.current.value = "";
    inputRef5.current.value = "";
    inputRef6.current.value = "";
    inputRef7.current.value = "";
  };
  const [projectDetails, setProjectDetails] = useState({
    project_title: "",
    expected_calls: "",
  });

  const handelChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setProjectDetails({ ...projectDetails, [key]: value });
  };

  const handleEditorChange = (content) => {
    setValue(content);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getProjects = () => {
    // setLoading1(true);
    axios
      .get(`${API_PATH.PRJECT_LIST}/${currentPage}`, {
        headers: headers,
      })

      .then((res) => {
        setLoading1(true);

        setAlldatas(res.data.count);
      })
      .catch((error) => {
        // setLoading1(false);
      });
  };

  // useEffect(() => {
  //   getProjects();
  // }, [currentPage]);

  const handelChecked = (e) => {
    if (e.target.checked) {
      setCall_recorded((current) => current + 1);
    } else {
      setCall_recorded((current) => current - 1);
    }
    setIsSubscribed((current) => !current);
  };

  const handleInputChangeCompany = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleInputChangeCountry = (e, index) => {
    const { id, value } = e.target;
    const list = [...selectList];
    list[index][id] = value;
    setSelectList(list);
  };

  const handleInputChangeEmail = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailList];
    list[index][name] = value;
    checkEmail(list[index].email);
    setEmailList(list);
  };

  const checkEmail = (email) => {
    //let emailData = [];

    //for (var Email in emailList) {
    const regEx = /[@]/;
    if (!regEx.test(email)) {
      //setChekemail(true)
      setMessage("");
    } else {
      return setMessage("Email is invalid");
    }
    // }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (data, index) => {
    if (data === "input") {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    } else if (data === "select") {
      const list = [...selectList];
      list.splice(index, 1);
      setSelectList(list);
    } else if (data === "email") {
      const list = [...emailList];
      list.splice(index, 1);
      setEmailList(list);
    }
  };

  // handle click event of the Add button
  const handleAddClick = (data) => {
    if (data === "input") {
      setInputList([...inputList, { company: "" }]);
    } else if (data === "select") {
      setSelectList([...selectList, { country: "" }]);
    } else if (data === "email") {
      setEmailList([...emailList, { email: "" }]);
    }
  };

  const header = {
    "Content-Type": "application/json",
  };
  const handleSubmitOne = (e) => {
    e.preventDefault();
    // let countryData = [];
    // let companyData = [];
    // let emailData = [loginDetails.user.email.split("@")[0]];
    let payload = {
      project_title: projectDetails?.project_title,
      expected_calls: projectDetails?.expected_calls,
      project_deadline: startDate,
      question: value,
      user_id: loginDetails?.user?._id,
    };
    if (
      payload.project_title === "" ||
      payload.expected_calls === "" ||
      payload.question === "" ||
      payload.project_deadline === ""
    ) {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      <></>;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);

    let countryData = [];
    let companyData = [];
    let emailData = [];
    if (loginDetails?.user?.email) {
      emailData?.push(loginDetails?.user?.email?.split("@")[0]);
    } else {
      console.error("Email is not defined in loginDetails.user.email");
    }

    for (var Country in selectList) {
      countryData?.push(selectList[Country]?.country);
    }

    for (var Company in inputList) {
      companyData?.push(inputList[Company]?.company);
    }

    for (var Email in emailList) {
      if (
        emailList[Email]?.email !== emailData[0] &&
        emailList[Email]?.email !== ""
      ) {
        emailData?.push(emailList[Email]?.email);
      }
    }

    let payload = {
      project_title: projectDetails?.project_title,
      user_id: loginDetails?.user?._id,
      invite_team: emailData?.join(","),
      country: countryData[0],
      target_companies: companyData?.join(","),
      question: value,
      project_deadline: startDate?.toISOString(), // Convert to ISO string
      expected_calls: projectDetails?.expected_calls,
    };

    try {
      const res = await fetch(`${API_PATH?.CREATE_PROJECT}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (res.ok) {
        const result = await res.json();
        if (
          result?.status === true &&
          result?.message === "Insert successfully."
        ) {
          setSpinner(false);
          // document.getElementById("projects-modal2")?.click();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          return toast.success("Project created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error(result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        return toast.error("Server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return toast.error("Something went wrong. Server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div
        className="modal fade pro-jects1 bd-example-modal-lg"
        id="createProjectModal1"
        tabIndex="-1"
        aria-labelledby="createProjectModal1Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-ps-modal-1 ">
                <h2 className="text-center"> Create New Project</h2>
                <p
                  className="text-center mt-4"
                  style={{ color: "#526fd7", fontSize: "20px !important" }}
                >
                  {" "}
                  Detail out your requirement to launch a custom recruitment
                  request{" "}
                </p>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label d-flex">
                        {" "}
                        Project Title *{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        // placeholder="Project name"
                        name="project_title"
                        onChange={
                          handelChange
                          // setInputValue(e.target.value);
                        }
                        ref={inputRef}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label d-flex">
                        Number of Expected Calls *
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="expected_calls"
                        onChange={
                          handelChange
                          // setIsNumber(e.target.value)
                        }
                        // value={isNumber}
                        ref={inputRef2}
                      >
                        <option value>Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="form-label d-flex">
                        Project Deadline *
                      </label>
                      {/* <DatePicker
                        dateRender={(current) => {
                          const style = {};
                          if (current.date() === 1) {
                            style.border = "1px solid #1890ff";
                            style.borderRadius = "50%";
                          }
                          return (
                            <div
                              className="ant-picker-cell-inner"
                              style={style}
                            >
                              {current.date()}
                            </div>
                          );
                        }}
                      /> */}
                      <DatePicker
                        style={{ cursor: "default" }}
                        inputProps={{ readOnly: true }}
                        className="form-select"
                        minDate={new Date()}
                        selected={startDate}
                        placeholderText="MM/DD/YYYY"
                        onChange={setStartDate}
                        dateFormat="dd/MM/yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label d-flex">
                        {" "}
                        Project Overview *{" "}
                      </label>
                      <div className="mainl">
                        {/* <textarea
                          className="form-control d-none"
                          placeholder="Project brief"
                        ></textarea>
                        <figure className="mainl-c d-none">
                          <img src="images/mail-pic.png" />
                        </figure> */}

                        <div className="">
                          <Editor
                            apiKey="4zsbw146cia5a0qmlr6rucoz820ppv27r55qepau8vvaz016"
                            initialValue=""
                            branding={false}
                            setValue={setValue}
                            value={value}
                            id="notesEditor"
                            onEditorChange={handleEditorChange}
                            init={{
                              height: 233,
                              menubar: false,
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                              ],
                              toolbar:
                                "undo redo | blocks | " +
                                "bold italic forecolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | ",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          />
                        </div>

                        {/* <MailEditor
                          value={value}
                          onChange={setValue}
                          setValue={setValue}
                          config={config}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {projectDetails.project_title === "" ||
                projectDetails.expected_calls === "" ||
                value === "" ||
                startDate === "" ? (
                <div className="col-lg-12">
                  <Button
                    className="btn col-lg-5 mx-auto btnsm"
                    onClick={handleSubmitOne}
                  >
                    {" "}
                    Next{" "}
                  </Button>
                </div>
              ) : (
                <div className="col-lg-12">
                  <Button
                    data-bs-target="#exampleModalToggle2"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    className="btn col-lg-5 mx-auto btnsm"
                  // onClick={handleSubmit1}
                  >
                    {" "}
                    Next{" "}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade pro-jects1 bd-example-modal-lg"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabindex="-1"
      >
        <div className="modal-dialog notesPopups modal-lg">
          <div className="modal-content">
            <div className="modal-header d-flex" style={{ border: "none" }}>
              <Link
                className="text-decoration-none"
                //   component={Link}
                to="#createProjectModal1"
                data-bs-target="#createProjectModal1"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                type="button"
              >
                <ArrowBackIosIcon style={{ color: "gray" }} />
              </Link>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body d-flex justify-content-center align-items-center p-0">
              <form>
                <div
                  className="header2 text-center px-4"
                  style={{ textAlign: "center" }}
                >
                  <div className="main-header" style={{ marginBottom: "40px" }}>
                    <h3 className="heading text-center mb-3">
                      You're almost done
                    </h3>
                    {/* <p className="header-text px-2 mb-4">
                      Lorem ipsum dolor sit amet, consectetur
                    </p> */}
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label d-flex"
                          style={{ color: "black", fontWeight: "500" }}
                        >
                          Geography of experts
                        </label>

                        {selectList.map((x, i) => {
                          return (
                            <div
                              className="box"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                              key={i}
                            >
                              <Select
                                style={{
                                  borderRadius: "10px",
                                  border: "1px solid rgb(210,210,210)",
                                  minWidth: "width: 80%",
                                  marginRight: "1vh",
                                }}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                options={countryList}
                                placeholder="Select Country"
                                onChange={(e) =>
                                  handleInputChangeCountry(
                                    {
                                      target: {
                                        value: e.value,
                                        id: "country",
                                      },
                                    },
                                    i
                                  )
                                }
                                ref={inputRef5}
                              />

                              <div className="btn-box">
                                {selectList.length !== 1 && (
                                  <DangerousOutlinedIcon
                                    className="mr10"
                                    onClick={() =>
                                      handleRemoveClick("select", i)
                                    }
                                  />
                                )}
                                {selectList.length - 1 === i && (
                                  <AddCircleOutlineIcon
                                    onClick={() => handleAddClick("select")}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className="form-label d-flex"
                          style={{ color: "black", fontWeight: "500" }}
                        >
                          Target Companies
                        </label>
                        {inputList.map((x, i) => {
                          return (
                            <div
                              className="box"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                              key={i}
                            >
                              <input
                                style={{
                                  padding: "1vh",
                                  borderRadius: "10px",
                                  border: "1px solid rgb(210,210,210)",
                                  width: "80%",
                                  marginRight: "1vh",
                                }}
                                name="company"
                                placeholder="Google"
                                value={x.company}
                                onChange={(e) => handleInputChangeCompany(e, i)}
                                ref={inputRef6}
                              />

                              <div className="btn-box">
                                {inputList.length !== 1 && (
                                  <DangerousOutlinedIcon
                                    className="mr10"
                                    onClick={() =>
                                      handleRemoveClick("input", i)
                                    }
                                  />
                                )}
                                {inputList.length - 1 === i && (
                                  <AddCircleOutlineIcon
                                    onClick={() => handleAddClick("input")}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label
                          htmlFor=""
                          className="form-label d-flex"
                          style={{ color: "black", fontWeight: "500" }}
                        >
                          Invite your team (Optional){" "}
                        </label>
                        {emailList.map((x, i) => {
                          return (
                            <div
                              className="box"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                              key={i}
                            >
                              {/* <input
                                  style={{
                                    padding: "1vh",
                                    borderRadius: "10px",
                                    border: "1px solid rgb(210,210,210)",
                                    width: "66%",
                                  }}
                                  placeholder="Google,Facebook, ..."
                                  
                                /> */}
                              <div className="col-sm-11 my-1">
                                <label
                                  className="sr-only"
                                  htmlFor="inlineFormInputGroupUsername"
                                >
                                  Enter Email
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inlineFormInputGroupUsername"
                                    placeholder="Enter Email"
                                    name="email"
                                    value={x.email}
                                    onChange={(e) =>
                                      handleInputChangeEmail(e, i)
                                    }
                                    ref={inputRef7}
                                  />
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      {"@" +
                                        loginDetails?.user?.email?.split(
                                          "@"
                                        )[1]}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="btn-box">
                                {emailList.length !== 1 && (
                                  <DangerousOutlinedIcon
                                    className="mr10"
                                    onClick={() =>
                                      handleRemoveClick("email", i)
                                    }
                                  />
                                )}
                                {emailList.length - 1 === i && (
                                  <AddCircleOutlineIcon
                                    onClick={() => handleAddClick("email")}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                        {message === "" ? (
                          <></>
                        ) : (
                          <p className="text-danger">{message}</p>
                        )}
                      </div>
                    </div>
                    <div className="radio2">
                      <Checkbox
                        className="me-2"
                        onChange={handelChecked}
                        value={isSubscribed}
                        name="call_recorded"
                        style={{ color: "#526fd7" }}
                        required
                      ></Checkbox>

                      {/* <input
                        type="checkbox"
                        name="call_recorded"
                        className="checkbox-round"
                        id="Record"
                        value={isSubscribed}
                      /> */}
                      <label style={{ color: "#526fd7" }}>
                        Request calls to be recorded
                      </label>
                    </div>
                    <div className="d-grid gap-2 col-10 mx-auto py-4">
                      {message === "" ? (
                        <Button
                          id="closebtn"
                          style={{
                            // lineHeight: "20px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            outline: "none",
                            textTransform: "inherit",
                            // height: "5vh",
                          }}
                          className="btn col-lg-5 mx-auto btnsm"
                          type="primary"
                          loading={spinner}
                          // data-bs-target="#exampleModalToggle3"
                          // data-bs-toggle="modal"

                          //data-bs-dismiss="modal"
                          // to="#exampleModalToggle3"
                          onClick={handleSubmit}
                        >
                          Submit{" "}
                        </Button>
                      ) : (
                        <Button
                          style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            outline: "none",
                            textTransform: "inherit",
                          }}
                          className="btn col-lg-5 mx-auto btnsm"
                          type="primary"
                          disabled
                          // data-bs-target="#exampleModalToggle3"
                          // data-bs-toggle="modal"

                          //data-bs-dismiss="modal"
                          // to="#exampleModalToggle3"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <NavLink
        to={""}
        data-bs-toggle="modal"
        data-bs-target="#createProjectModal1"
        style={{
          color: "#5746EC",
        }}
      >
        click here
      </NavLink>
      ,{" "}
      {loading1 ? (
        <>
          {allDatas > 10 ? (
            <div className="pagination-background">
              <Pagination1
                activePage={currentPage}
                itemsCountPerPage={10}
                totalItemsCount={allDatas}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                itemclassName="page-item"
                linkclassName="page-link"
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
