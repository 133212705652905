import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import { BsChevronLeft } from "../Utils/Icons";
import CompleteTable from "./CompleteTable";
import DeclineTable from "./DeclineTable";
import RequestTable from "./RequestTable";
import ScheduledTable from "./ScheduledTable";
import SubmitTable from "./SubmitTable";

import { createSearchParams, useLocation } from "react-router-dom";
import API_URL from "../Constants/api-path";
import axios from "axios";

function ProjectsDetailsExpert() {
  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let project_id = query.get("project_id");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const headers = React.useMemo(() => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.access_token}`,
  }), [userData?.access_token]);

  const [expertList, setExpertList] = useState([]);
  const [loading, setLoading] = useState([]);

  const getExperts = React.useCallback(() => {
    setLoading(true);
    axios
      .get(
        `${API_URL.Client_Project_Expert_List}/${project_id}`,

        { headers }
      )
      .then((res) => {
        setExpertList(res?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [headers, project_id]);

  useEffect(() => {
    getExperts()
  }, [getExperts])


  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div request-page projects-scope" style={{ paddingTop: "0px" }}>
                <div className="d-md-flex top-ps-seection justify-content-between align-items-center">
                  <h4 className="d-flex align-items-center">
                    <NavLink
                      to={{
                        pathname: "/projects-details",
                        search: `?${createSearchParams({ project_id })}`,
                      }}
                      style={{ textAlign: "left" }}
                    >
                      <BsChevronLeft className="me-3 search-btn-back" />
                    </NavLink>
                    Experts
                  </h4>
                  <div className="d-flex justify-content-center align-items-center">
                    <ul
                      className="nav nav-pills mb-3 mt-4"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <NavLink
                          to={{
                            pathname: "/projects-details",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          className="nav-link"
                        >
                          Overview
                        </NavLink>
                      </li>
                      <li className="nav-item" role="presentation">
                        <NavLink
                          to={{
                            pathname: "/projects-details-message",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          className="nav-link"
                        >
                          Messages
                        </NavLink>
                      </li>

                      <li className="nav-item" role="presentation">
                        <NavLink
                          to={{
                            pathname: "/projects-details-expert",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          className="nav-link"
                        >
                          Experts
                        </NavLink>
                      </li>
                    </ul>
                  </div>

                  <button
                    className="btn add-ps-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#carteProjectModal"
                    style={{ visibility: "hidden" }}
                  >
                    {" "}
                    <BsPlusLg /> Create Project{" "}
                  </button>
                </div>

                <span className="sp-hr w-100" />

                <div className="request-body-sec projects-details-page details-message-part">
                  <div className="expert-tables">
                    {/* <ul
                      className="nav nav-pills mb-3 mx-auto"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab1"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Submitted
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab2"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Requested
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab3"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          Scheduled
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab4"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          Completed
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          data-bs-toggle="pill"
                          data-bs-target="#expert-tab5"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          Declined
                        </button>
                      </li>
                    </ul> */}
                    <div className="tab-content mt-3" id="pills-tabContent">
                      {/* <div
                        className="tab-pane fade show active"
                        id="expert-tab1"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      > */}
                      <div className="submit-table comon-tabs-expert">
                        <SubmitTable project_id={project_id} expertList={expertList}
                          loading={loading} getExperts={getExperts} />
                      </div>
                      {/* </div> */}

                      {/* <div
                        className="tab-pane fade"
                        id="expert-tab2"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <RequestTable project_id={project_id} expertList={expertList}
                            loading={loading} getExperts={getExperts} />
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="expert-tab3"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <ScheduledTable project_id={project_id} expertList={expertList}
                            loading={loading} getExperts={getExperts} />
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="expert-tab4"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <CompleteTable project_id={project_id} expertList={expertList}
                            loading={loading} getExperts={getExperts} />
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="expert-tab5"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="submit-table comon-tabs-expert">
                          <DeclineTable project_id={project_id} expertList={expertList}
                            loading={loading} getExperts={getExperts} />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
export default ProjectsDetailsExpert;
