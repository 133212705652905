import React, { useState, useEffect } from "react";
import { Spinner, Modal, Button } from "react-bootstrap";
import { DeleteOutlined } from "@ant-design/icons";
import { Space, Menu, message, Tooltip, Tag } from "antd";
import API_PATH from "../Constants/api-path";
import { MdOutlineClose } from "react-icons/md";
import _ from "lodash";

function NextynLi({ inviteTeamDashboard, project_id }) {
  const [Loading, setLoading] = useState(true);
  const [modal3Open, setModal3Open] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [intData, setInvtData] = useState("");
  const [handleDeleteExpert, setDeleteExpert] = useState(false);


  useEffect(() => {
    setLoading(false);
  }, [inviteTeamDashboard]);

  const editName = (email) => {
    if (typeof email !== "string") {
      return "NoName";
    }

    let emailID = email.split("@")[0];
    let [firstName, lastName] = emailID.split(".");
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : "";
    return `${firstName} ${lastName}`.trim();
  };

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };


  const editEmail = (email) => {
    const formattedName = email
      .split("@")[0]
      .split(".")
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return `${formattedName}@nextyn.com`;
  };

  const removeNextynFromEmail = (email) => {
    if (email && email.includes('@nextyn.com')) {
      return email.replace('@nextyn.com', '');
    }
    return email;
  };


  const DeleteInvite = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.PROJECT_INVITE_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.access_token}`,
        },
        body: JSON.stringify({
          project_id: project_id,
          invite_team: intData,
        }),
      });

      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          setModal3Open(false);
          message.success("Invite_team remove successfully!!");
          window.location.reload();
        } else {
          message.error("something went wrong server error");
        }
      }
    } catch (error) {
      setLoading(false);

      message.error("something went wrong server error");
    }
    setLoading(false);
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setModal3Open(true);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Space align="center" size={8}>
          <DeleteOutlined />
          <span>Delete</span>
        </Space>
      </Menu.Item>
    </Menu>
  );

  const footer = (
    <div>
      <Button key="cancel" onClick={() => setModal3Open(false)}>
        No stay
      </Button>
      <Button
        key="submit"
        onClick={() => {
          setModal3Open(false);
          DeleteInvite();
        }}
      >
        Yes Delete
      </Button>
    </div>
  );

  const getInvite = (inviteData) => {
    setInvtData(inviteData);
  };

  if (!Loading) {
    return (
      <>
        <div className="client-teams-ul-li">
          <ul className="list-unstyled">
            {inviteTeamDashboard?.map((item, index) => (
              <li key={index}>
                <div className="teams-li d-flex justify-content-between align-items-center flex-1">
                  <div>
                    <figure>
                      <div key={index} className="avatarImg d-flex">
                        <img
                          width="40px"
                          src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${editName(
                            item.email || item.name || "NoName"
                          )}`}
                          alt={item.email || item.name || "NoName"}
                        />
                      </div>
                    </figure>
                    <h5 className="m-0" key={index}>
                      {item?.name ? editName(item?.email || item?.name) : null}
                      {item.email && !item.email.endsWith("@nextyn.com") ? (
                        <span className="d-block">{
                          <Tooltip title={`${item.email}@nextyn.com`}>
                            {_.truncate(
                              `${item.email}@nextyn.com` || "",
                              {
                                length: 20,
                                omission: "...",
                              }
                            )}
                          </Tooltip>
                        }</span>
                      ) : (
                        <>
                          <span className="d-block"> {_.truncate(
                            `${item.email}` || "",
                            {
                              length: 20,
                              omission: "...",
                            }
                          )}</span>
                        </>
                      )}
                      <span className="d-block" style={{ color: "#c40a36" }}>
                        {item?.Invite === "Invite pending"
                          ? `Invite pending (sent ${convertDate(item?.created_at)})`
                          : null}
                      </span>

                    </h5>
                  </div>

                  {/* <Tag
                    size="small"
                    color="#5746ec"
                    style={{
                      background: "transparent",
                      color: "#5746ec",
                      border: "1px solid #5746ec",
                      fontSize: "10px",
                      fontWeight: "bolder",
                    }}
                  >
                    POC
                  </Tag> */}
                </div>

                <Modal
                  show={handleDeleteExpert}
                  onHide={() => setDeleteExpert(false)}
                  backdrop="static"
                  keyboard={false}
                  centered
                  size={"md"}
                  scrollable={true}
                  dialogClassName={"bootStrapModal"}
                  contentClassName={"bootStrapModalContent"}
                >
                  <Modal.Body className="position-relative">
                    <MdOutlineClose
                      className="position-absolute"
                      style={{ right: "15px", top: "15px", fontSize: "25px" }}
                      onClick={() => setDeleteExpert(false)}
                    />
                    <div className="text-left">
                      <h4 className="modalHeader">Are you sure?</h4>
                    </div>
                    <div>
                      Do you really want to delete these records? This process cannot be undone.
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <Button className="btn btn-secondary"
                        style={{ border: "none" }} onClick={() => setDeleteExpert(false)}>
                        No Stay
                      </Button>
                      <Button className="btn btn-primary ms-2"
                        style={{ backgroundColor: "#5746ec", border: "none" }} onClick={() => DeleteInvite()}>Yes Delete</Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <div role="status" style={{ textAlign: "center" }}>
          <Spinner animation="border" />
        </div>
      </div>
    );
  }
}
export default NextynLi;
